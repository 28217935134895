<template lang="pug">
Dialog.dialog-dominio-valor-salvar(:modal="true" header= "Adicionar Valor de Domínio" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    .main-wrapper.dominios-valor-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto; max-width: 500px;')
                        .p-col-12
                            label.form-label Domínio:
                            Dropdown(v-model='mnemonico' :options='options.dominio_mestre' disabled
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')
                        .p-col-12
                            label.form-label Domínio Mestre:
                            Dropdown(v-model='model.cd_pai' :options='options.dominio_mestre' @input="getDominioValor()"
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione (Opcional)' :filter='true')
                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_dominio_mestre_id.$error }")
                            label.form-label Domínio Valor Mestre:
                            Dropdown(v-model='$v.model.cd_dominio_mestre_id.$model' :options='options.dominio_valor_mestre' :disabled="options.dominio_valor_mestre.length < 1"
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione (Opcional)' :filter='true' v-tooltip.top="options.dominio_valor_mestre.length < 1 ? 'Informe o dominio antes' : ''")
                            .feedback--errors(v-if='submitted && $v.model.cd_dominio_mestre_id.$error')
                                .form-message--error(v-if="!$v.model.cd_dominio_mestre_id.requiredIf") Como você selecionou um domínio mestre, <b>domínio valor mestre</b> é obrigatório.
                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_valor.$error }")
                            label.form-label Descrição:
                            InputText(type='text' v-model='$v.model.ds_valor.$model')
                            .feedback--errors(v-if='submitted && $v.model.ds_valor.$error')
                                .form-message--error(v-if="!$v.model.ds_valor.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.ds_valor.required") <b>Descrição</b> é obrigatório.
                        .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ie_valor.$error }")
                            label.form-label Valor:
                            InputText(type='text' v-model='$v.model.ie_valor.$model')
                            .feedback--errors(v-if='submitted && $v.model.ie_valor.$error')
                                .form-message--error(v-if="!$v.model.ie_valor.required") <b>Valor</b> é obrigatório.
                        .p-col-12.p-md-6
                            label.form-label Ordem de listagem:
                            InputNumber(v-model='model.nr_ordem' placeholder='(Opcional)')
                        .p-col-12
                            label.form-label Cor:
                            .p-inputgroup
                                InputText(type='text' v-model='model.ds_cor')
                                ColorPicker(v-model='model.ds_cor' defaultColor='ff0000')
                    
                        .p-col-3
                            label.form-label Visualização:
                            InputSwitch(v-model='model.ie_permissao_vis')
                            
                        .p-col-3
                            label.form-label Edição:
                            InputSwitch(v-model='model.ie_permissao_edi')

                        .p-col-3
                            label.form-label Remoção:
                            InputSwitch(v-model='model.ie_permissao_del')

                        .p-col-3
                            label.form-label Status:
                            InputSwitch(v-model='model.ie_status')

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.mt-2
                            Button(label='Salvar' type="submit")
</template>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import InputNumber from 'primevue/inputnumber'
    import InputSwitch from 'primevue/inputswitch'
    import ColorPicker from 'primevue/colorpicker'

    import { Dominio, DominioValor } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, InputNumber, Dialog,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, InputSwitch, ColorPicker
        },
        directives: { tooltip: Tooltip },
        props: [
            'display',
            'mnemonico',
            'valor',
        ],
        mounted() {
            this.waiting = true
           
            this.getDominios()

            if (this.valor) {
                DominioValor.findVal(this.valor, this.mnemonico).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])
                        
                        this.model.id = response.data.id
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        }
                    }
                    this.waiting = false
                    if (this.model.cd_pai) this.getDominioValor()
                })
            } else this.waiting = false
        },
        computed: {
            show: {
                get() { 
                    return this.display; 
                },
                set(value) { 
                    if (!value) {
                        this.resetModel()
                        this.$emit('close'); 
                    }
                }
            },
        },
        data () {
            return {
                model: {
                    ds_valor: '',
                    ie_valor: '',
                    cd_dominio_mestre_id: null,
                    cd_pai: null,
                    ie_permissao_vis: false,
                    ie_permissao_edi: false,
                    ie_permissao_del: false,
                    ie_status: true,
                    ds_cor: '',
                    nr_ordem: null,
                },
                options: {
                    dominio_mestre: [],
                    dominio_valor_mestre: [],
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    ds_valor: { required, minLength: minLength(2) },
                    ie_valor: { required },
                    cd_dominio_mestre_id: {
                        requiredIf(value) {
                            if (!value && this.model.cd_pai) return false
                            return true
                        }
                    },
                }
            }
            return v
        },
        methods: {
            resetModel() {
                this.model.ds_valor = ''
                this.model.ie_valor = ''
                this.model.cd_dominio_mestre_id = null
                this.model.cd_pai = null
                this.model.ie_permissao_vis = false
                this.model.ie_permissao_edi = false
                this.model.ie_permissao_del = false
                this.model.ie_status = true
                this.model.ds_cor = ''
                this.model.nr_ordem = null
                this.waiting = true
                this.waitingForm = false
                this.submitted = false
            },
            getDominios (){
                Dominio.findAll().then(response=>{
                    if (response.status == 200) {
                        this.options.dominio_mestre.push({ value: null, text: '- Selecione -' })
                        response.data.forEach(item => {
                            this.options.dominio_mestre.push({text: item.ds_dominio, value: item.ds_mnemonico})
                        })
                    }
                })
            },
            getDominioValor() {
                this.options.dominio_valor_mestre = []
                if (this.model.cd_pai) {
                    DominioValor.find(this.model.cd_pai).then(response=>{
                        if (response.status == 200) {
                            this.options.dominio_valor_mestre.push({ value: null, text: '- Selecione -' })
                            response.data.forEach(item => {
                                this.options.dominio_valor_mestre.push({text: item.ds_valor, value: item.id})
                            })
                        }
                    })
                }
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                if (dataSend.ds_cor && dataSend.ds_cor[0] !== "#") dataSend.ds_cor = "#" + dataSend.ds_cor
                delete dataSend.cd_pai

                this.waitingForm = true
                let metodo = dataSend.id ? 'patch' : 'save'
                let params =  dataSend.id ? [dataSend.ie_valor, this.mnemonico, dataSend] : [this.mnemonico, dataSend]

                DominioValor[metodo](...params).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$emit('close')
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.$emit('salvo')
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
        },
    }
</script>

<style lang="scss">

.dialog-dominio-valor-salvar {
    width: 800px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
    .dominios-valor-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
}
</style>