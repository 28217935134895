<template lang="pug">
.main-wrapper.dominios-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Domínios</b>

    Dialog.dialogApagar(header='Remover Domínio' :visible.sync='dialogApagar' :modal='true')
        p Deseja remover o domínio <b>{{ dialogApagar_data.ds_dominio }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')

    DialogDominioValor(v-if='dialogValorDominio.display' :display='dialogValorDominio.display' :mnemonico='dialogValorDominio.mnemonico' @close='dialogValorDominio.display = false')

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid
            .p-col-12.p-md-6
                label.form-label Domínio:
                .p-inputgroup
                    InputText(placeholder='Domínio'
                        @keyup.enter.native='() => !waiting && applyFilters()'
                        @input='(val) => !val && applyFilters()'
                        v-model='filters.ds_dominio'
                    )
                    Button(icon='jam jam-search' @click='applyFilters()' :disabled='waiting')
            .p-col-12.p-md-4
                label.form-label Domínio Mestre:
                ProgressBar(v-if="options.dominio_mestre.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione'
                        :options='options.dominio_mestre'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_dominio_mestre_id'
                        filter
                    )
            .p-col-12.p-md-2
                label.form-label Status:
                .p-inputgroup
                    SelectButton(
						:options='options.status'
						optionLabel='label'
						optionValue='value'
						@input='applyFilters()'
						v-model='filters.ie_status'
					)

    .ta-right.my-2
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/dominios/salvar/`)")

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.nm_setor" style='position: relative')
                        .ta-left
                            p <b>Descrição:</b> {{ props.data.ds_dominio }}
                            p <b>Mnemônico:</b> {{ props.data.ds_mnemonico }}
                            p <b>Domínio Mestre:</b> {{ props.data.dominio_mestre?.ds_dominio }}
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Valores do domínio'"
                                icon="pi pi-external-link"
                                @click="dialogValorDominio.mnemonico = props.data.ds_mnemonico; dialogValorDominio.display = true"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/dominios/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Domínios')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list")
                Column(headerStyle='width: 70px' bodyStyle='text-align:center' header='Status')
                    template(#body='props')
                        ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                        .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data)')
                            i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                Column(headerStyle='width: 29%;' field='ds_dominio' header='Descrição')
                Column(headerStyle='width: 29%;' field='ds_mnemonico' header='Mnemônico')
                Column(headerStyle='width: 29%;' field='dominio_mestre' header='Domínio Mestre')
                    template(#body='props')
                        span {{ props.data.dominio_mestre?.ds_dominio }}
                Column(headerStyle='width: 12%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Valores do domínio'"
                                icon="pi pi-external-link"
                                @click="dialogValorDominio.mnemonico = props.data.ds_mnemonico; dialogValorDominio.display = true"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/dominios/salvar/${ props.data.ds_mnemonico }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .dominios-listar {
        .status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
		.waitingStatus, .waitingObservacoes {
			width: 29px;
			height: auto;
		}
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import SelectButton from 'primevue/selectbutton'

    import { Dominio } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import axios from "axios"
    import DialogDominioValor from './DialogDominioValorListar.vue'

    const _ = require('lodash')

    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'dominios') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () {
            this.getDominios()
        },
        components: {
            ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown, MultiSelect, DialogDominioValor,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, SelectButton, },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                cancelToken: null,
                params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 300, { leading: true }),
                filters: {
                    ds_dominio: '',
                    cd_dominio_mestre_id: null,
                    ie_status: null,
                },
                options: {
                    dominio_mestre: [],
                    status: [
                        {
                            label: 'Ativo',
                            value: true,
                        },
                        {
                            label: 'Inativo',
                            value: false,
                        }
                    ],
                },
                order: '-id',
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0,
                },
                dialogValorDominio: {
                    display: false,
                    mnemonico: '',
                },
            }
        },
        methods: {
            alterarStatus (item) {
				this.waitingStatus = true
				Dominio.patch({ ds_mnemonico: item.ds_mnemonico, ie_status: ! item.ie_status }).then(response => {
					this.waitingStatus = false
					if (([200, 201]).includes(response.status)) {
						item.ie_status = ! item.ie_status
						if (item.ie_status)
							this.$toast.success('Domínio habilitado', { duration: 3000 })
						else
							this.$toast.info('Domínio desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
            getDominios (){
                Dominio.findAll().then(response=>{
                    this.options.dominio_mestre.push({ value: null, label: '- Selecione -' })
                    if (response.status == 200) {
                        response.data.forEach(item => {
                            this.options.dominio_mestre.push({label: item.ds_dominio, value: item.id})
                        })
                    }
                })
            },
            getList (params) {
                if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                this.waiting = true
                return Dominio.findAll(params, this.cancelToken.token).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    this.cancelToken = null
                })
            },
            applyFilters(page, force) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, order: this.order }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key] || (key === 'ie_status' && this.filters.ie_status === false)) params[key] = this.filters[key]  })

                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params) && !force) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            remove () {
                this.waitingApagar = true
                Dominio.remove(this.dialogApagar_data.ds_mnemonico).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Domínio REMOVIDO com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters(1, true)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>
